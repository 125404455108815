<template>
  <div class="page-class page-user-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <silver-coin-box
      :columns="columns"
      :rows="categoryList"
      :loading="loading"
      :showDownload="false"
      :showUpload="true"
      :showSearch="false"
      :showToolbar="true"
      :showPagination="true"
      :pagination="pagination"
      downloadText="DownloadList"
      emptyText="No Data Found."
      uploadText="Upload Category Wise List"
      @show-upload="onUpload"
      @download="onDownload"
    >
    </silver-coin-box>
  </div>
</template>

<script>
import moment from 'moment';
import Vue from 'vue';
import csv from 'csvtojson';
import router from '@/router';
import { mapState, mapActions } from 'vuex';
import _ from 'lodash';
import SilverCoinBox from '../../components/SilverCoinBox.vue';
import { numeric } from 'vuelidate/lib/validators';

export default {
  components: { SilverCoinBox },
  name: 'SilverCoinList',
  metaInfo() {
    return {
      title: 'Category Wise List',
      meta: [
        {
          name: 'barcode-scanner',
          content: `Download mis-barcode`
        }
      ]
    };
  },
  mounted() {
    this.listStore({ router });
    // if (_.isEmpty(this.$route.query)) {
    //   this.listCategory({
    //     query: {
    //       ...this.$route.query,
    //       startdate: moment().format('YYYY-MM-DD'),
    //       enddate: moment().format('YYYY-MM-DD')
    //     }
    //   });
    // } else {
    this.listCategory({ query: this.$route.query });
    // }
  },
  beforeRouteUpdate(to, from, next) {
    this.listCategory({ query: to.query });
    next();
  },
  data() {
    return {
      title: 'Category Wise List',
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { ' text-center': true },
          textKey: 'rowNum',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          class: { 'text-center': true },
          textKey: 'storeid',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Article Code',
          class: { 'text-center': true },
          textKey: 'articleCode',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Article Description',
          class: { 'text-center': true },
          textKey: 'articleDescription',
          width: '15%'
        },
        {
          type: 'string',
          headerText: 'Group',
          class: { 'text-center': true },
          textKey: 'group',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Class',
          class: { 'text-center': true },
          textKey: 'class',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Family',
          class: { 'text-center': true },
          textKey: 'family',
          width: '11%'
        },
        {
          type: 'string',
          headerText: 'Sub Family',
          class: { 'text-center': true },
          textKey: 'subFamily',
          width: '11%'
        },
        {
          type: 'string',
          headerText: 'Sequence',
          class: { 'text-center': true },
          textKey: 'numbering',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Submitted By',
          class: { 'text-center': true },
          textKey: 'submittedByName',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Created At',
          class: { 'text-center': true },
          textKey: 'createdAt',
          width: '15%'
        }
      ]
    };
  },
  computed: {
    ...mapState('barcode', ['loading', 'baseUrl2', 'pagination', 'categoryList', 'downloadCategoryList']),
    ...mapState('store', ['storeList']),
    ...mapState('darkMode', ['isDarkMode'])
  },
  methods: {
    ...mapActions('barcode', ['listCategory', 'downloadCategory', 'upload']),
    ...mapActions('store', ['listStore']),
    onDownload() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.downloadCategory({ query: { ...this.$route.query, page_size: 999999, page: 1 } });
      } else {
        this.downloadCategory({
          query: {
            ...this.$route.query,
            page_size: 9999999,
            page: 1,
            startdate: moment().format('YYYY-MM-DD'),
            enddate: moment().format('YYYY-MM-DD')
          }
        });
      }
    },
    createCsv() {
      const csv = `Store Id,Article Code,Article Description,Group,Class,Category,Sub Category,Sequence\n`;
      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = 'sampleFile.csv';
      anchor.click();
    },
    async onUpload() {
      await Vue.swal({
        title: 'Upload file',
        input: 'file',
        inputAttributes: {
          'aria-label': 'Upload your file in csv format'
        },
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upload',
        denyButtonText: 'Download Sample',
        denyButtonColor: '#3a4',
        showCancelButton: true,
        showDenyButton: true,
        cancelButtonText: 'Close',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        }
      }).then(result => {
        if (result.isDenied) {
          this.createCsv();
        }

        if (result.value) {
          const reader = new FileReader();
          reader.onload = async () => {
            csv({
              noheader: true,
              output: 'csv'
            })
              .fromString(reader.result)
              .then(csvRow => {
                const inputs = csvRow.slice(1, csvRow.length).map(i => {
                  const values = i.reduce((acc, curr, index) => {
                    if (index === 0) {
                      if (i[0].length !== 4) {
                        Vue.swal({
                          title: 'Access Denied',
                          text: `Store Id ${i[0].charAt(0).toUpperCase() + i[1].slice(1)} must be 4 digits`,
                          type: 'error',
                          confirmButtonText: 'Ok',
                          icon: 'error',
                          background: this.isDarkMode ? '#464d54' : '#ffffff',
                          customClass: {
                            title: this.isDarkMode ? 'text-light' : 'text-dark',
                            content: this.isDarkMode ? 'text-light' : 'text-dark'
                          }
                        });
                        return null;
                      }
                      const inUppercase = i[0].toUpperCase();
                      const compareStoreIdFromDatabse = this.newStoreList.filter(store => store.storeid == inUppercase);
                      if (compareStoreIdFromDatabse.length === 0) {
                        Vue.swal({
                          title: 'Access Denied',
                          text: `Store id ${
                            i[0].charAt(0).toUpperCase() + i[0].slice(1)
                          } is not found in database! Please enter correct store id.`,
                          type: 'error',
                          confirmButtonText: 'Ok',
                          icon: 'error',
                          background: this.isDarkMode ? '#464d54' : '#ffffff',
                          customClass: {
                            title: this.isDarkMode ? 'text-light' : 'text-dark',
                            content: this.isDarkMode ? 'text-light' : 'text-dark'
                          }
                        });
                        return null;
                      }
                      acc.storeid = curr.toUpperCase();
                    }
                    if (index === 1) {
                      acc.articleCode = curr;
                    }
                    if (index === 2) {
                      acc.articleDescription = curr;
                    }
                    if (index === 3) {
                      const inUppercase = i[3].toUpperCase();
                      acc.group = inUppercase;
                    }
                    if (index === 4) {
                      const inUppercase = i[4].toUpperCase();
                      acc.class = inUppercase;
                    }
                    if (index === 5) {
                      const inUppercase = i[5].toUpperCase();
                      acc.family = inUppercase;
                    }
                    if (index === 6) {
                      const inUppercase = i[6].toUpperCase();
                      acc.subFamily = inUppercase;
                    }
                    if (index === 7) {
                      acc.numbering = curr;
                    }
                    return acc;
                  }, {});
                  return values;
                });
                this.upload({ data: inputs, router });
              });
          };
          reader.readAsBinaryString(result.value);
        }
      });
    }
  },

  watch: {
    storeList(newValue) {
      let comparingStores = [];
      comparingStores = newValue.map(store => ({
        storeid: store.storeid
      }));
      this.newStoreList = comparingStores;
      this.stores = newValue;
    },
    downloadCategoryList(newValue) {
      if (newValue.length) {
        let csv = `Row Num,Store Id,Article Code,Article Description,Group,Class,Submitted By,Created At\n`;
        newValue.forEach((item, index) => {
          csv += `${index + 1},${item.storeid},${item.articleCode},${item.articleDescription},${item.group},${
            item.class
          },${item.submittedByName},${item.createdAt}\n`;
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Category List.csv';
        anchor.click();
      }
    }
  }
};
</script>

<style></style>
